
@media (min-width: 1400px) {
    .multiSelectITP{
        height:40px !important;
    }
  }

  @media (min-width: 1600px) {
    .multiSelectITP{
        height:45px !important;
    }
  }  